@font-face {
  font-family: "proxima-bold";
  src: url("./assets/font/proxima_ssv/Proxima-Nova-Alt-Bold.otf");
}

@font-face {
  font-family: "proxima-regular";
  src: url("./assets/font/proxima_ssv/ProximaNova-Regular.otf");
}

body {
  margin: 0;
  padding: 0;
  font-family: "proxima-regular";
  padding-top: 0px;
  color: #170d42;
  background: #f4f4f4;
}
* {
  scroll-behavior: smooth;
}
.body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.bg-grey {
  background: #f5f6f7 !important;
}
.border-radius {
  border-radius: 5px;
}
.box-shadow {
  box-shadow: 0px 2px 4px rgba(14, 86, 124, 0.165308);
}
.bg-white {
  background: white !important;
}
footer {
  margin-top: auto;
  padding-bottom: 10px;
  font-size: 12px;
  font-family: "proxima-bold";
  color: grey;
}
.font-bold {
  font-weight: 600;
  font-family: "proxima-bold";
}

.font64 {
  font-size: 64px !important;
}
.font45 {
  font-size: 45px !important;
}

.font30 {
  font-size: 30px !important;
}
.font18 {
  font-size: 18px !important;
}

@media (max-width: 900px) {
  .header-text h2,
  .font64 {
    font-size: 40px !important;
  }
  .sm-border {
    border: solid 0.6px #c4c4c4;
  }
  .sm-p-none {
    padding: 0 !important;
  }
  .sm-mt {
    margin-top: 2rem !important;
  }
  .sm-d-none {
    display: none !important;
  }
  .sm-mt-none {
    margin-top: 0 !important;
  }
  .sm-pt-none {
    padding-top: 0 !important;
  }
  .sm-ml-none {
    margin-left: 0 !important;
  }
  .sm-text-left {
    text-align: left !important;
  }
  .sm-reverse {
    flex-direction: column-reverse;
  }
  .sm-px-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 800px) {
  .lg-my-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .lg-pl-0 {
    padding-left: 0 !important;
  }
  .lg-pr-0 {
    padding-right: 0 !important;
  }
  .lg-ml {
    margin-left: 1rem !important;
  }
}
.line-height {
  line-height: 1;
}
a,
a:hover,
a:active {
  text-decoration: none;
}
.click {
  cursor: pointer;
}
.hidden {
  overflow: hidden;
}
.p-r {
  position: relative;
}
.fa-heart {
  color: #eb5e28;
  font-size: 14px;
  -webkit-animation: breathing 1s ease-out infinite normal;
  animation: breathing 1s ease-out infinite normal;
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
.navbar-nav .nav-link {
  color: #170d42 !important;
  font-size: 18px;
  font-family: "proxima-bold";
}
.nav-link.btn {
  color: white !important;
}
.navbar-brand {
  width: 110px;
}
#head {
  background: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.52),
      rgba(12, 2, 10, 0.73)
    ),
    url("./assets/img/hands-heart-love-305530.jpg");
  background-size: cover;
  background-position: center;
}
.home header {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.common-input {
  border: none;
  height: 50px;
  border: 0;
  background: #f7f7f7;
}
.common-input::placeholder {
  font-size: 14px !important;
  color: #919aa3 !important;
}
textarea {
  resize: none;
  min-height: 175px;
}
.contact {
  min-height: 80vh;
  background: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.52),
      rgba(117, 19, 93, 0.73)
    ),
    url("./assets/img/human-hands-making-heart-sign-2072598.jpg");
  background-position: center;
  background-size: cover;
}
.request {
  min-height: 80vh;
  background: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.52),
      rgba(117, 19, 93, 0.73)
    ),
    url("./assets/img/photo-of-people-laughing-3569601.jpg");
  background-position: center;
  background-size: cover;
}
.testimonies {
  min-height: 80vh;
  background: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.52),
      rgba(117, 19, 93, 0.73)
    ),
    url("./assets/img/silhouette-photography-of-group-of-people-jumping-during-1000445.jpg");
  background-position: center;
  background-size: cover;
}
.contact-card {
  margin-top: -12vh;
  background: white;
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    letter-spacing: 1px !important;
  }
}
.overlay-card {
  margin-top: -10vh;
}
.common-btn {
  background: #ff2603;
  color: white !important;
  padding: 0.6rem 1rem !important;
  font-family: "proxima-bold";
  transition: all 0.3s ease;
}
.common-btn-outline {
  border: #ff2603 solid 2px;
  color: #ff2603 !important;
  padding: 0.6rem 1rem !important;
  font-family: "proxima-bold";
  transition: all 0.3s ease;
}
.btn:focus,
.btn:active {
  outline: none !important;
  border: none;
}
.opacity {
  opacity: 0.8;
}
.modal-logo {
  width: 100px;
}
.need-card {
  box-shadow: 0px 2px 4px rgba(14, 86, 124, 0.165308);
  min-height: 200px;
  border-radius: 10px;
  text-align: center;
  margin-top: 2rem;
  color: #170d42;
  font-size: 20px;
  font-family: "proxima-bold";
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 900px) {
  .need-card {
    font-size: 16px;
  }
}
.need-card img {
  width: 50px;
  margin-bottom: 10px;
}
.font12 {
  font-size: 12px !important;
}
.font20 {
  font-size: 20px !important;
}
.font30 {
  font-size: 30px !important;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  width: 30px;
  height: 30px;
  background: #869791 !important;
  border-radius: 50%;
  color: white !important;
  font-size: 20px !important;
  text-align: center;
}


/* The check-container */
.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
}

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1px;
  left: 17px;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: #CFCFDF solid .7px;
}
  
  /* On mouse-over, add a grey background color */
  .check-container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .check-container input:checked ~ .checkmark {
    background-color: #a70026;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .check-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .check-container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform:  rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }